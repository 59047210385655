<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps(['filter', 'filtersStore']);

const value = ref([]);

const arrayV=ref([])

function setValue() {
  props.filtersStore.updateValue(props.filter, value.value);
}

onMounted(() => {
  const initialValue = props.filtersStore.selected_filters[props.filter.slug]?.value;
  if (initialValue) {
    const values = initialValue.split(',');
    value.value = props.filter.data_source.filter(item => values.includes(item.slug));
  }
});
</script>


<template>
  <div class="border-b border-b-slate-200 pb-3">
    <div class="px-3 mt-3">
      {{ filter.name }}
    </div>
    <div class="px-3 mt-1">
      <div class="grid grid-cols-2">
      <template v-for="(item, idx) in filter.data_source" :key="idx">
      <template v-if="(filter.filter_value_more > 0 && filter.filter_value_more >= idx)">
        <w-checkbox :value="item"  @change="setValue()" v-model="value">
          <template #label>
            {{ item.label_value || item.value }}
            {{ filter.filter_value_more == idx ? '+' : '' }}  
          </template>
        </w-checkbox>
      </template>
    </template>
    </div>
  </div>
  </div>
    
    
  
</template>
